import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { MatSnackBar } from '@angular/material/snack-bar';

export class Data {
  constructor() { }
}

@Injectable()
export class DataService {
  public userimage = '../../assets/images/others/user.jpg'
  public Data = new Data();
  public url = 'assets/data/';
  constructor(public http: HttpClient, public snackBar: MatSnackBar) { }
  public getJSON(name: string): Observable<any> {
    return this.http.get<any>(this.url + name + '.json');
  }
  public getStates(): Observable<any> {
    return this.http.get<any>(this.url + 'states.json');
  }
  public getBrandStore(name: string): Observable<any> {
    return this.http.get<any>(this.url + name + '.json');
  }

  public getBrandStoreImages(name: string): any[] {
    let slides = [];
    switch (name) {
      case 'burberry':
        slides = [
          {
            'image': 'http://productimages.bslog.com/images/products/MaxSite2020/Brands/BurberryHero1.jpg',
          },
          {
            'file': 'http://productimages.bslog.com/images/products/MaxSite2020/Brands/BurberryHero2.jpg',

          }
        ];
        break;
      case 'callaway':
        slides = [
          { 'image': 'https://productimages.bslog.com/images/products/MaxSite2020/Brands/CallawayHero1.jpg', 'caption': '' },
          { 'image': 'https://productimages.bslog.com/images/products/MaxSite2020/Brands/CallawayHero2.jpg', 'caption': '' }
        ];
        break;
      case 'coach':
        slides = [
          {
            'image': 'https://productimages.bslog.com/images/products/MaxSite2020/Brands/CoachHero1.jpg',

          },
          {
            'image': 'https://productimages.bslog.com/images/products/MaxSite2020/Brands/CoachHero2.jpg',

          }
        ];
        break;
      case 'garmin':
        slides = [
          {
            'image': 'https://productimages.bslog.com/images/products/MaxSite2020/Brands/GarminHero1.jpg',

          },
          {
            'image': 'https://productimages.bslog.com/images/products/MaxSite2020/Brands/GarminHero2.jpg',

          }
        ];
        break;
      case 'michaelkors':
        slides = [
          { 'image': 'https://productimages.bslog.com/images/products/MaxSite2020/Brands/MichaelKorsHero1.jpg', 'caption': '' },
          { 'image': 'https://productimages.bslog.com/images/products/MaxSite2020/Brands/MichaelKorsHero2.jpg', 'caption': '' }
        ];
        break;
    }
    return slides;
  }
  public getBanners(): Observable<any[]> {
    return this.http.get<any[]>(this.url + 'banners.json');
  }
  // [['barware',15420],['fitness',17000],['handbags',12105],['headphones',11112],['jewelry',13100],['sunglasses',12120]]
  public getCategoryIcons() {
    var usrobj = JSON.parse(localStorage.getItem('currentUser'));
    if (usrobj.langPreference === 'Spanish') {
      return [
        /* {
          name: 'featured',
          id: 29900,
          image: 'assets/images/categories/shop-category-esp-featured-2301.jpg' //20220114 jno temp category for promo
        }, */
        /* {
          name: 'Mary Kay-Branded Items',
          id: 29900,
          image: 'assets/images/categories/shop-category-esp-yogacoming.jpg'
        }, */
        {
          name: 'Earrings',
          id: 13115,
          image: 'assets/images/categories/shop-category-esp-earring.jpg'
        },
        {
          name: 'poster',
          id: 655,
          image: 'assets/images/categories/shop-category-esp-quarterly.jpg'
        },
        {
          name: 'Vacuums',
          id: 14100,
          image: 'assets/images/categories/shop-category-esp-vacuums.jpg'
        },
        {
          name: 'Mary Kay-Branded Items',
          id: 29900,
          image: 'assets/images/categories/shop-category-esp-branded_2409.jpg'
        },
        {
          name: 'BLUETOOTH/WIRELESS SPEAKERS',
          id: 11196,
          image: 'assets/images/categories/shop-category-esp-bluetoothSpeakers.jpg'
        },
        {
          name: 'logoitem',
          id: 656,
          image: 'assets/images/categories/shop-category-esp-logoitem-2409.jpg'
        },
        {
          name: 'CARRY-ONS (22" AND BELOW)',
          id: 19110,
          image: 'assets/images/categories/shop-category-esp-carryOn.jpg'
        },
        {
          name: 'LADIES BICYCLES',
          id: 17110,
          image: 'assets/images/categories/shop-category-esp-bicycles.jpg'
        },
        {
          name: 'POTS AND PANS',
          id: 15300,
          image: 'assets/images/categories/shop-category-esp-cookware.jpg'
        }
      ];
    } else {
      return [
        /* {
          name: 'featured',
          id: 29900,
          image: 'assets/images/categories/shop-category-featured-2301.jpg' //20220114 jno temp category for promo
        }, */
        /* {
          name: 'Mary Kay-Branded Items',
          id: 29900,
          image: 'assets/images/categories/shop-category-yogacoming.jpg'
        }, */
        {
          name: 'Earrings',
          id: 13115,
          image: 'assets/images/categories/shop-category-earring.jpg'
        },
        {
          name: 'poster',
          id: 654,
          image: 'assets/images/categories/shop-category-quarterly.jpg'
        },
        {
          name: 'Vacuums',
          id: 14100,
          image: 'assets/images/categories/shop-category-vacuums.jpg'
        },
        {
          name: 'Mary Kay-Branded Items',
          id: 29900,
          image: 'assets/images/categories/shop-category-branded_2409.jpg'
        },
        {
          name: 'BLUETOOTH/WIRELESS SPEAKERS',
          id: 11196,
          image: 'assets/images/categories/shop-category-bluetoothSpeakers.jpg'
        },
        {
          name: 'logoitem',
          id: 656,
          image: 'assets/images/categories/shop-category-logoitem-2409.jpg'
        },
        {
          name: 'CARRY-ONS (22" AND BELOW)',
          id: 19110,
          image: 'assets/images/categories/shop-category-carryOn.jpg'
        },
        {
          name: 'LADIES BICYCLES',
          id: 17110,
          image: 'assets/images/categories/shop-category-bicycles.jpg'
        },
        {
          name: 'POTS AND PANS',
          id: 15300,
          image: 'assets/images/categories/shop-category-cookware.jpg'
        }
      ];
    };
  }

  public getBannersMK() {
    var usrobj = JSON.parse(localStorage.getItem('currentUser'));
    if (usrobj.langPreference === 'Spanish') {
      return [
        {
          name: 'featured',
          id: 29900,
          image: '../assets/images/banners/MaryKayHero-esp-2207.jpg'
        },
        {
          name: 'featured',
          id: 29900,
          image: '../assets/images/banners/FeaturedHero-esp.jpg'
        },
      ];
    } else {
      return [
        {
          name: 'featured',
          id: 29900,
          image: '../assets/images/banners/MaryKayHero-2207.jpg'
        },
        {
          name: 'featured',
          id: 29900,
          image: '../assets/images/banners/FeaturedHero.jpg'
        },
      ];
    };
  }

  public getBrands() {
    return [
      {
        name: 'burberry',
        image:
          'https://productimages.bslog.com/brand-page-tiles/Brand%20Store%20Tiles-121217-16.jpg'
      },
      {
        name: 'callaway',
        image:
          'https://productimages.bslog.com/brand-page-tiles/Brand%20Store%20Tiles-121217-5.jpg'
      },
      {
        name: 'coach',
        image:
          'https://productimages.bslog.com/brand-page-tiles/Brand%20Store%20Tiles-121217-15.jpg'
      },
      {
        name: 'garmin',
        image:
          'https://productimages.bslog.com/brand-page-tiles/Brand%20Store%20Tiles-121217-8.jpg'
      },
      {
        name: 'michaelkors',
        image:
          'https://productimages.bslog.com/brand-page-tiles/Brand%20Store%20Tiles-121217-6.jpg'
      },
      {
        name: '',
        image: 'assets/images/brands/Brand-Store-Tiles-020718-shop-all.jpg'
      }
    ];
  }
  public getMonths() {
    return [
      { value: '01', name: 'January' },
      { value: '02', name: 'February' },
      { value: '03', name: 'March' },
      { value: '04', name: 'April' },
      { value: '05', name: 'May' },
      { value: '06', name: 'June' },
      { value: '07', name: 'July' },
      { value: '08', name: 'August' },
      { value: '09', name: 'September' },
      { value: '10', name: 'October' },
      { value: '11', name: 'November' },
      { value: '12', name: 'December' }
    ];
  }



  public getYears() {
    const d = new Date();
    const retval = [];
    const thisyr = d.getFullYear();
    for (let i = 0; i < 6; i++) {
      retval.push(thisyr + i);
    }

    return retval;
  }

  public getSortings() {
    var usrobj = JSON.parse(localStorage.getItem('currentUser'));
    if (usrobj.langPreference === 'Spanish') {
      const sortings = [{ lbl: 'Precio: Baja a Alta', field: 'Mileage', reverse: false },
      { lbl: 'Precio: Alta a baja', field: 'Mileage', reverse: true },
      { lbl: 'Nombre del producto A-Z', field: 'NameEsp', reverse: false },
      { lbl: 'Nombre del producto Z-A', field: 'NameEsp', reverse: true },
      ];
      return sortings;
    }
    else {
      const sortings = [{ lbl: 'Price: Low to High', field: 'Mileage', reverse: false },
      { lbl: 'Price: High to Low', field: 'Mileage', reverse: true },
      { lbl: 'Product Name A-Z', field: 'Name', reverse: false },
      { lbl: 'Product Name Z-A', field: 'Name', reverse: true },
      ];
      return sortings;
    }
  }

  public getSortingsOptimized() {
    var usrobj = JSON.parse(localStorage.getItem('currentUser'));
    if (usrobj.langPreference === 'Spanish') {
      const sortings = [{ lbl: 'Relevancia', field: 'SortMK', reverse: false },
      { lbl: 'Precio: Baja a Alta', field: 'Mileage', reverse: false },
      { lbl: 'Precio: Alta a baja', field: 'Mileage', reverse: true },
      { lbl: 'Nombre del producto A-Z', field: 'NameEsp', reverse: false },
      { lbl: 'Nombre del producto Z-A', field: 'NameEsp', reverse: true },
      ];
      return sortings;
    }
    else {
      const sortings = [{ lbl: 'Relevance', field: 'SortMK', reverse: false },
      { lbl: 'Price: Low to High', field: 'Mileage', reverse: false },
      { lbl: 'Price: High to Low', field: 'Mileage', reverse: true },
      { lbl: 'Product Name A-Z', field: 'Name', reverse: false },
      { lbl: 'Product Name Z-A', field: 'Name', reverse: true },
      ];
      return sortings;
    }
  }


  public getLetters() {
    const letters = [
      'A',
      'B',
      'C',
      'D',
      'E',
      'F',
      'G',
      'H',
      'I',
      'J',
      'K',
      'L',
      'M',
      'N',
      'O',
      'P',
      'Q',
      'R',
      'S',
      'T',
      'V',
      'W',
      'Y',
      'Z'
    ];
    return letters;
  }

}
