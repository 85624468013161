import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LoginUser } from '../_models/loginuser';
import { ApiService } from './api-service';
import { UtilityService } from './utility.service';
import { Router } from '@angular/router';
//914

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<LoginUser>;
  public currentUser: Observable<LoginUser>;
  constructor(
    private api: ApiService,
    private utilityService: UtilityService,
    private router: Router
  ) {
    this.currentUserSubject = new BehaviorSubject<LoginUser>(
      JSON.parse(localStorage.getItem('currentUser'))
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  // return the current user
  public get currentUserValue(): LoginUser {
    return this.currentUserSubject.value;
  }
  // return if current user exists-maybe change later
  public get loggedin() {
    return (this.currentUserValue);
  }

  /* autoLogin(_username: string, _contestTotal: number, _subsidiaryCode: string, _contestCode: string, _starCount: number, _bwhFlag: string, _contestSubcode: string) {
    // https://maxapi.bslog.com/api/Auth/Login
    const u = this.utilityService.encrypt(_username);
    const p = 'gibberish'; //this.utilityService.encrypt(_password);
    const param = {
      grant_type: 'password',
      password: p.toString(),
      username: u.toString()
    };
    return this.api.post('Auth/Login', param).pipe(
      map((user: any) => {
        // login successful if there's a jwt token in the response
        if (user && user.access_token) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          user.role = this.utilityService.getUserRole(user); // we convert permissionLevel to a role
          user.language = this.utilityService.getUserLanguage(user); // we convert langPreference to a language
          localStorage.setItem('currentUser', JSON.stringify(user));
          this.currentUserSubject.next(user);
        }
        return user;
      })
    );
  } */

  login(_username: string, _password: string) {
    // https://maxapi.bslog.com/api/Auth/Login
    const u = this.utilityService.encrypt(_username);
    const p = this.utilityService.encrypt(_password);
    const param = {
      grant_type: 'password',
      password: p.toString(),
      username: u.toString()
    };
    return this.api.post('Auth/Login', param).pipe(
      map((user: any) => {
        // login successful if there's a jwt token in the response
        if (user && user.access_token) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          user.role = this.utilityService.getUserRole(user); // we convert permissionLevel to a role
          user.language = this.utilityService.getUserLanguage(user); // we convert langPreference to a language
          user.restricted = this.utilityService.getIsRestricted(user);
          localStorage.setItem('currentUser', JSON.stringify(user));
          this.currentUserSubject.next(user);
        }
        return user;
      })
    );
  }

  forgotPassword(_email: string) {
    // https://maxapi.bslog.com/api/Login/GenerateCode?email=svajda@rymaxinc.com
    //Not correct http://192.168.91.211/api/Login/ResetPassword?email=jyanes@rymaxinc.com
    return this.api.get('Login/GenerateCode?email=' + _email);
  }
  refreshToken(): any {
    // http://localhost:55135/api/Auth/RefreshToken
    const user = this.currentUserValue;
    const u = this.utilityService.encrypt(user.decryptedUsername);
    const rt = user.refresh_token;
    const param = {
      username: u.toString(),
      refresh_token: rt,
      grant_type: 'refresh_token'
    };
    return this.api.post('Auth/RefreshToken', param)
      .subscribe((retval: any) => {
        if (retval && retval.access_token) {
          // remove the old user
          this.logout();
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('currentUser', JSON.stringify(user));
          this.currentUserSubject.next(user);
          this.utilityService.alert('You have been logged back in.');
        }
      },
        error => {
          console.log('Error from Auth/RefreshToken: ', error.message);
        }
      )
  }

  askToStayLoggedIn() {
    // logout first
    const popup = this.utilityService.openDialog('Please confirm you want to stay logged in')
      .subscribe(retval => {
        if (retval === false) {
          this.router.navigate(['/login']);
        } else {
          if (this.loggedin) {
            this.refreshToken();
          } else {
            this.utilityService.alert('Sorry, you need to login again');
          }
        }
      });
  }
  logout() {
    // remove user from local storage to log user out
    localStorage.clear();
    // localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
  }

  loginAuto(_username: string, _contestTotal: number, _subsidiaryCode: string, _contestCode: string, _starCount: number, _bwhFlag: string, _contestSubCode: string) {
    // https://maxapi.bslog.com/api/Auth/Login
    const u = this.utilityService.encrypt(_username);
    const p = this.utilityService.encrypt("M@ryPr0j42");
    const param = {
      grant_type: 'password',
      password: p.toString(),
      username: u.toString(),
      contestTotal: _contestTotal,
      subsidiaryCode: _subsidiaryCode.toString(),
      contestCode: _contestCode.toString(),
      starCount: _starCount,
      bwhFlag: _bwhFlag.toString(),
      contestSubCode: _contestSubCode.toString(),
      plnnm: _username
    };
    return this.api.post('Auth/AutoLogin', param).pipe(
      map((user: any) => {
        // login successful if there's a jwt token in the response
        if (user && user.access_token) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          user.role = this.utilityService.getUserRole(user); // we convert permissionLevel to a role
          user.language = this.utilityService.getUserLanguage(user); // we convert langPreference to a language
          user.restricted = this.utilityService.getIsRestricted(user);
          localStorage.setItem('currentUser', JSON.stringify(user));
          this.currentUserSubject.next(user);
        }
        return user;
      })
    );
  }
}
