import { Routes, RouterModule } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';

import { PagesComponent } from './pages/pages.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { LoginComponent } from './login/login.component';
import { AuthGuard } from './_helpers/auth.guard';
import { SigninRedirectCallbackComponent } from './signin-redirect-callback/signin-redirect-callback.component';
export const routes: Routes = [
  { path: 'login', component: LoginComponent },
  {
    path: 'signin-callback',
    component: SigninRedirectCallbackComponent
  },
  {
    path: '',
    component: PagesComponent,
    children: [
      { path: '', loadChildren: './pages/home/home.module#HomeModule', canActivate: [AuthGuard] },
      {
        path: 'account',
        loadChildren: './pages/account/account.module#AccountModule',
        data: { breadcrumb: 'Account Settings' }
      },
      {
        path: 'wishlist',
        loadChildren: './pages/wishlist/wishlist.module#WishlistModule',
        data: { breadcrumb: 'Wishlist' }
      },
      {
        path: 'wishlistEsp',
        loadChildren: './pages/wishlistEsp/wishlistEsp.module#WishlistEspModule',
        data: { breadcrumb: 'Wishlist' }
      },
      {
        path: 'cart',
        loadChildren: './pages/cart/cart.module#CartModule',
        data: { breadcrumb: 'Cart' }
      },
      {
        path: 'checkout',
        loadChildren: './pages/checkout/checkout.module#CheckoutModule',
        data: { breadcrumb: 'Checkout' }
      },
      {
        path: 'brands',
        loadChildren: './pages/brands/brands.module#BrandsModule',
        data: { breadcrumb: 'Brands' }
      },
      {
        path: 'brandstore',
        loadChildren: './pages/brandstore/brandstore.module#BrandstoreModule',
        data: { breadcrumb: '' }
      },

      {
        path: 'generic',
        loadChildren: './pages/generic/generic.module#GenericModule',
        data: { breadcrumb: '/' }
      },
      {
        path: 'recognition',
        loadChildren: './pages/recognition/recognition.module#RecognitionModule',
        data: { breadcrumb: '/' }
      },
      {
        path: 'faq',
        loadChildren: './pages/faq/faq.module#FaqModule',
        data: { breadcrumb: 'Frequently asked questions' }
      },
      {
        path: 'products',
        loadChildren: './pages/products/products.module#ProductsModule',
        data: { breadcrumb: '/' }
      },
      {
        path: 'calculator',
        loadChildren: './pages/calculator/calculator.module#CalculatorModule',
        data: { breadcrumb: 'Credit Calculator' }
      },
      {
        path: 'poster',
        loadChildren: './pages/poster/poster.module#PosterModule',
        data: { breadcrumb: 'Quarterly Poster' }
      },
      {
        path: 'posterEsp',
        loadChildren: './pages/posterEsp/posterEsp.module#PosterEspModule',
        data: { breadcrumb: 'Cartel Trimestral' }
      },
      {
        path: 'logoitem',
        loadChildren: './pages/logoitem/logoitem.module#LogoitemModule',
        data: { breadcrumb: 'Logo Item' }
      }
    ]
  },
  { path: '**', component: NotFoundComponent }
];

export const routing: ModuleWithProviders = RouterModule.forRoot(routes, {
  // preloadingStrategy: PreloadAllModules // <- comment this line for activate lazy load
  // useHash: true
});
