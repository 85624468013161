import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Settings, AppSettings } from './app.settings';
import { AuthConfig, OAuthService } from 'angular-oauth2-oidc';
import { ActivatedRoute } from '@angular/router';
//import {HttpUrlEncodingCodec } from '@angular/common/http';
import 'rxjs/add/operator/filter';
//import { OAuthService, AuthConfig, JwksValidationHandler } from 'angular-oauth2-oidc';
/* import { AuthService } from './services/auth.service'; */
/* import { User } from 'oidc-client'; */
//914 real
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {
  loading = false;
  public settings: Settings;
  appid: string;
  constructor(private oauthService: OAuthService, public appSettings: AppSettings, public router: Router, private route: ActivatedRoute,
  ) {
    this.settings = this.appSettings.settings;
  }
  public authCodeFlowConfig: AuthConfig = {
    issuer: 'https://mk.marykayintouch.com',
    redirectUri: window.location.origin + '/signin-callback',
    postLogoutRedirectUri: window.location.origin + '/signin-callback',
    clientId: '3MVG9mclR62wycM2HqFDbhd7SoC4wDiWH8iakEdkQIeoDntgKRsIDhuH5jKwSg9CE2MNAg3u.rrQX_xz7wZVY',
    requestAccessToken: true,
    responseType: 'code',
    scope: 'openid',
    showDebugInformation: true,
  };
  /*  private userdata: string; */
  /* public userAuthenticated = false;

  constructor(private _authService: AuthService, public router: Router) {
    this._authService.loginChanged
      .subscribe(userAuthenticated => {
        this.userAuthenticated = userAuthenticated;
      })
  } */


  ngOnInit() {
    // Discovery Document of your AuthServer as defined by OIDC
    this.oauthService.oidc = true; // ID_Token
    this.oauthService.requestAccessToken = true;
    this.oauthService.configure(this.authCodeFlowConfig);
    let url = 'https://mk.marykayintouch.com/.well-known/openid-configuration';
    localStorage.setItem('mksso', 'Yes');
    // Load Discovery Document and then try to login the user
    this.oauthService.loadDiscoveryDocument(url).then(() => {

      // This method just tries to parse the token(s) within the url when
      // the auth-server redirects the user back to the web-app
      // It dosn't send the user the the login page
      this.oauthService.tryLogin({});

    });
    //console.log("Entered OnInit of app.component");
    //console.log(this.userAuthenticated);
    //console.log("******************************************");
    //https://mk.marykayintouch.com/.well-known/openid-configuration
    // this.oauthService.configure(this.authCodeFlowConfig);
    //this.oauthService.tryLogin();
    //this.router.navigate(['']);  //redirect other pages to homepage on browser refresh
    /*  this._authService.isAuthenticated()
       .then(userAuthenticated => {
         this.userAuthenticated = userAuthenticated;
       }) */

    /*  if (this.userAuthenticated) {
       this.userdata = this.usr.access_token;
       console.log(this.userdata);
     } */

  }

  ngAfterViewInit() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        window.scrollTo(0, 0);
      }
    });
  }

  /* btnLogin() {
    this._authService.login();
    //this.oauthService.initCodeFlow();
    //this.oauthService.initLoginFlow();
    //this.oauthService.configure(this.authCodeFlowConfig);
    ////this.oauthService.tokenValidationHandler = new JwksValidationHandler();
    //this.oauthService.loadDiscoveryDocumentAndTryLogin();
  }
 */
}
