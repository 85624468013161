import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DataService } from '../../_services/data.service';
import { Product } from '../../_models/product';
import { PromoProduct } from '../../_models/promoProduct';
import { ProductService } from 'src/app/_services/product.service';
import { CartService } from 'src/app/_services/cart.service';
import { ShopCart } from '../../_models/shop-cart';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-controls',
  templateUrl: './controls.component.html',
  styleUrls: ['./controls.component.scss']
})
export class ControlsComponent implements OnInit {
  @Input() product: Product;
  @Input() type: any;
  @Output() onOpenProductDialog: EventEmitter<any> = new EventEmitter();
  @Output() onQuantityChange: EventEmitter<any> = new EventEmitter<any>();
  cartSubscription: Subscription;
  shopcart: ShopCart;
  public count = 1;
  public prm: PromoProduct;
  inCart = false;
  wlVisible = true;
  atcEnable = false;
  public align = 'center center';
  constructor(
    public dataService: DataService,
    public snackBar: MatSnackBar,
    private cartService: CartService,
    private productService: ProductService,

  ) { }

  ngOnInit() {

    if (this.product) {

      this.cartSubscription = this.cartService.shopcartSubject.subscribe(sc => {
        this.shopcart = sc;

        this.inCart = sc.shopcartlst.filter(p => p.ProductID === this.product.ProductID).length > 0;

        //document.write(this.product.ProductID);

        // if (this.product.ProductID === localStorage.getItem('prmid')) {
        //   this.productService.getPromoItemByProdID(localStorage.getItem('prmid'))
        //     .subscribe((prmProduct: any) => {
        //       this.prm = prmProduct;
        //       this.wlVisible = false;
        //     });
        // }
        // else {
        //   this.wlVisible = true;
        // }

      });

    }




    this.layoutAlign();
  }

  layoutAlign() {
    if (this.type === 'all') {
      this.align = 'space-between center';
    } else if (this.type === 'wish') {
      this.align = 'start center';
    } else {
      this.align = 'center center';
    }
  }

  increment(count) {
    this.count++;
    const obj = {
      ProductID: this.product.ProductID,
      qty: this.count,
      subtotal: this.count * Number(this.product.Mileage)
    };
    this.changeQuantity(obj);
  }

  decrement(count) {
    if (this.count > 1) {
      this.count--;
      const obj = {
        ProductID: this.product.ProductID,
        qty: this.count,
        subtotal: this.count * Number(this.product.Mileage)
      };
      this.changeQuantity(obj);
    }
  }
  addToWishList(product: Product) {
    this.cartService.addWishListProduct(product);
  }

  addCartProduct(product: Product) {
    this.cartService.addCartProduct(product);
  }

  openDialog(event) {
    this.onOpenProductDialog.emit(event);
  }

  changeQuantity(value) {
    this.onQuantityChange.emit(value);
  }
}
